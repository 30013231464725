<template>

  <div ref="DatePicker"
       id="DatePicker"
       tabindex="-1"
       aria-disabled
       disabled>

    <v-menu
      ref="open"
      :close-on-content-click="false"
      v-model="open"
      :nudge-right="30"
      transition="scale-transition"
      offset-y
      tabindex="-1"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-text-field
          v-bind="$attrs"
          v-on="$listeners"
          v-model="inputVal"
          v-mask="MASK_DATA"
          slot="activator"
          :rules="rules"
          class="nonfocus"
        >
          <!-- @blur="Blur()" -->
          <v-icon 
            v-if="$props.value"
            :disabled="$attrs.readonly"
            slot="append"
            tabindex="-1"
            class="mr-2"
            @click="clearField"
          >
            mdi-close
          </v-icon>
          <v-icon 
            v-on="on"
            v-bind="attrs"
            :disabled="$attrs.readonly"
            slot="append" 
            tabindex="-1"
            @click="open = true"
          >
            mdi-calendar
          </v-icon>
        
        </v-text-field>
      </template>
      <v-date-picker
        v-model="inputVal_Calendar"
        title
        reactive
        @input="datepicker_atualiza(); open = false"
        tabindex="-1"
      >
      </v-date-picker>

    </v-menu>
  </div>

</template>

<script>
  import moment from "moment";

  export default {
    name: 'DatePicker',
    
    props: ['value',
            'rules'],

    data: () => ({
      open              : false,
      inputVal_Calendar : null,
      format_interno    : 'DD/MM/YYYY',
      MASK_DATA         : ['##/##/####', '##/##/####'],
    }),

    created() {
      //console.log('CREATED');
      if (this.$attrs.format) {
        this.MASK_DATA = [this.$attrs.format?.replace(/[A-Z]|[a-z]/g,'#'),
                          this.$attrs.format?.replace(/[A-Z]|[a-z]/g,'#')];
        this.format_interno = this.$attrs.format;
      }
    },

    mounted() {
      //console.log(this.$refs.DatePicker);
      //this.$refs.DatePicker.$el.setAttribute('background-color', '#FF0000');
      if (document.getElementById("DatePicker"))
        document.getElementById("DatePicker").removeAttribute("tabIndex");
    },

    watch: {
      inputVal(val) {
        if (val && val != 'undefined' && val.indexOf('/') >= 0 && val.length == 10) {
          //const lt_Aux = moment(val, 'DD/MM/YYYY').utcOffset('-0300', true).format('L');
          const lt_Aux = moment(val, 'DD/MM/YYYY').format();
          //console.log('WATCH inputVal=3.1', lt_Aux)
          //this.inputVal_Calendar = moment(lt_Aux, 'DD/MM/YYYY').utcOffset('-0300', true).format('YYYY-MM-DD')
          //this.inputVal_Calendar = moment(lt_Aux, 'DD/MM/YYYY').format('YYYY-MM-DD')
          this.inputVal_Calendar = moment(lt_Aux).format('YYYY-MM-DD')
          //console.log('WATCH inputValCalendar=3.2', this.inputVal_Calendar)
          this.$emit('input', lt_Aux);
        }
        // if (this.value && this.value != 'Invalid date' && this.value != 'undefined' && this.value.indexOf('T') >= 0) {
        //   this.inputVal_Calendar = moment(this.value).toISOString().split('T')[0];
        //   console.log('WATCH inputVal=2', this.value, this.inputVal_Calendar)
        // }
      }

    },

    computed: {
      inputVal: {
        get() {
          if (this.value && this.value != 'Invalid date' && this.value != 'undefined' && this.value.indexOf('T') >= 0) {
            //console.log('GET1', this.value)
            //return moment(moment(this.value).format('YYYY-MM-DD')).utcOffset('-0300', true).format('L')
            return moment(moment(this.value).format('YYYY-MM-DD')).format('L')
          }
          else {
            //console.log('GET2', this.value)
            return this.value;
          }
        },
        set(val) {
          //console.log('SET', val)
          if(val && val.length == this.format_interno.length && moment(val, this.format_interno).isValid()) 
            //this.$emit('input', moment(val, this.format_interno).utcOffset('-0300', true).format('L'));
            this.$emit('input', moment(val, this.format_interno).format('L'));
          else if(!val)
            this.$emit('input', null);
          //else 
          //  this.$emit('input', val);
        }
      }
    },

    methods : {

      datepicker_atualiza() {
        //console.log('datepicker_atualiza', this.inputVal_Calendar);
        //this.$emit('input', moment(moment(this.inputVal_Calendar), this.format_interno).utcOffset('-0300', true).format('L'));
        this.$emit('input', moment(moment(this.inputVal_Calendar), this.format_interno).format('L'));
      },

      clearField() {
        this.$emit('input', null);
      },

    }

  }

</script>

<style scoped>
  .v-menu__content {
    min-width: auto !important;
  }
/* :focus {
  background: pink;
  display: none;
  --tabindex: -1;
} */

/* .v-input {
  display: none;
} */

</style>



